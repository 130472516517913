import { TokenType } from "../types";

export const TokenDecimals: { [key in TokenType]: number } = {
  BETNFT: 18,
  USDT: 6,
};

export const OrdinalMap = {
  1: "1st",
  2: "2nd",
  3: "3rd",
  4: "4th",
  5: "5th",
  6: "6th",
  7: "7th",
  8: "8th",
};

export const PrizeTypes = {
  1: "WTA",
  2: "TOP 2",
  3: "TOP 3",
  4: "TOP 4",
  5: "LAST 3",
  6: "LTA",
};

// For Example, if the prize structure 100% for first, then it is WTA (Winner Takes All)
export function getRacePrizeStructureType(
  prizeStruct: number[]
): [string, number] | null {
  // first  trim the array to remove trailing zeros
  let trimmed = prizeStruct.slice(0);
  while (trimmed[trimmed.length - 1] === 0) {
    trimmed.pop();
  }

  //Check if the last 3 elements are non zero and the rest are zero

  const lastThree = trimmed.slice(-3);
  if (
    lastThree.every((val) => val !== 0) &&
    trimmed.slice(0, -3).every((val) => val === 0) &&
    trimmed.length > 4
  ) {
    return [PrizeTypes[5], 5]; // Returning the value of the last element, adjust as per requirement
  }

  const last = trimmed.slice(-1);
  if (
    last.every((val) => val !== 0) &&
    trimmed.slice(0, -1).every((val) => val === 0) &&
    trimmed.length >= 2
  ) {
    return [PrizeTypes[6], 6]; // Returning the value of the last element, adjust as per requirement
  }
  if (trimmed.length === 1) {
    return [PrizeTypes[1], 1];
  }
  if (trimmed.length === 2 && trimmed.slice(0, 2).every((val) => val !== 0)) {
    return [PrizeTypes[2], 2];
  }
  if (trimmed.length === 3) {
    return [PrizeTypes[3], 3];
  }
  if (trimmed.length === 4) {
    return [PrizeTypes[4], 4];
  }

  return null;
}
